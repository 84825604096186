import Api from "./Api";
export default {
  paginate(paginate) {
    return Api().post("/contract/page", paginate);
  },
  confirmSent(id) {
    return Api().get(`/contract/${id}/confirm`);
  },
  download(id) {
    return Api().get(`/contract/${id}/letter`, {
      responseType: "arraybuffer",
    });
  },
  /*
  findAll() {
    return Api().get("/contract");
  },

  findOne(id) {
    return Api().get(`/contract/${id}`);
  },

  save(id, form) {
    return Api().patch("/contract/" + id, form);
  },

  create(form) {
    return Api().post("/contract", form);
  },

  delete(id) {
    return Api().delete(`/contract/${id}`);
  },
  */
};
