<template>
  <q-table
    :rows="rows"
    :columns="columnsI18n"
    row-key="id"
    v-model:pagination="pagination"
    :loading="loading"
    :filter="filter"
    @request="onRequest"
    binary-state-sort
    class="sticky-header-table"
  >
    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-badge :color="statusColor(props.value)" class="text-uppercase">{{
          $t("status.contract_" + props.value)
        }}</q-badge>
      </q-td>
    </template>
    <template v-slot:body-cell-dateSent="props">
      <q-td :props="props">
        <span v-if="props.value">
          {{ $moment(props.value).format("DD/MM/YYYY HH:mm:ss") }}
        </span>
      </q-td>
    </template>
    <template v-slot:body-cell-created_at="props">
      <q-td :props="props">
        {{ $moment(props.value).format("DD/MM/YYYY HH:mm:ss") }}
      </q-td>
    </template>

    <template v-slot:body-cell-order="props">
      <q-td :props="props">
        <router-link
          :to="'/order/' + props.value.id"
          class="vito-link text-bold"
        >
          {{ props.value.id }}
        </router-link>
      </q-td>
    </template>

    <template v-slot:body-cell-contact="props">
      <q-td :props="props">
        <span v-if="props.value && props.value.address">
          {{ props.value.address.firstname }}
          {{ props.value.address.lastname }}. {{ props.value.address.address }},
          {{ props.value.address.zip }} {{ props.value.address.city }}
        </span>
      </q-td>
    </template>
    <template v-slot:body-cell-product="props">
      <q-td :props="props">
        <div
          v-bind="props"
          v-if="props.value"
          class="flex no-wrap q-gutter-x-sm justify-center"
        >
          <div style="width: 26px">
            <q-img :src="productImage(props.value)" />
          </div>
          <div class="flex flex-center">
            {{ props.value.name }}
            {{ props.value.weight }} kg
          </div>
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <div v-if="props.row.status !== 'sent'">
          <q-btn
            icon="mark_email_read"
            round
            dense
            @click="handleChange(props)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.confirmContractSent") }}
            </q-tooltip>
          </q-btn>
        </div>
        <div>
          <q-btn
            icon="download"
            round
            dense
            @click="handleDownload(props)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.downloadContract") }}
            </q-tooltip>
          </q-btn>
        </div>
      </q-td>
    </template>
  </q-table>
  <q-dialog v-model="confirmContractSent">
    <q-card style="width: 500px">
      <q-card-section
        class="row items-center justify-between no-wrap q-pb-none"
      >
        <div class="row no-wrap">
          <q-avatar
            icon="mark_email_read"
            color="green-vito"
            text-color="white"
          />
          <div class="column q-ml-md justify-center">
            {{ $t("title.confirmContractSent") }}
          </div>
        </div>
        <div class="column justify-start">
          <q-btn icon="close" flat round dense v-close-popup />
        </div>
      </q-card-section>
      <q-card-section class="q-pa-lg q-gutter-lg row">
        <div>
          <q-btn icon="content_copy" round dense @click="copyContact()">
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.copyToClipboard") }}
            </q-tooltip>
          </q-btn>
        </div>
        <div>
          {{ selectedContract.contact.address.firstname }}
          {{ selectedContract.contact.address.lastname }}<br />
          {{ selectedContract.contact.address.address }}<br />
          {{ selectedContract.contact.address.zip }}
          {{ selectedContract.contact.address.city }}
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          :label="$t('button.cancel')"
          unelevated
          size="md"
          class="text-green-vito"
          v-close-popup
        />
        <q-btn
          flat
          :label="$t('button.confirm')"
          unelevated
          size="md"
          class="bg-green-vito text-white"
          @click="saveChange()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { exportFile } from "quasar";
import ContractApi from "../../apis/Contract";
import { ContractStatus } from "./contract.enum";
import { copyToClipboard } from "quasar";
import { Buffer } from 'buffer';

export default {
  props: ["store", "filter"],

  data: () => {
    return {
      selectedContract: null,
      confirmContractSent: false,
      loading: false,
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      rows: [],
      refreshCounter: 0,
    };
  },
  methods: {
    async handleChange(props) {
      this.selectedContract = props.row;
      this.confirmContractSent = true;
    },
    async handleDownload(props) {
      console.log(props);
      try {
        const contractDoc = await ContractApi.download(props.key);
        if (contractDoc) {
          const status = exportFile(
            `contract-${props.key}.docx`,
            Buffer.from( new Uint8Array(contractDoc.data)),
            {
              mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }
          );

          if (status === true) {
            // browser allowed it
          } else {
            // browser denied it
            console.error("Error: " + status);
          }
        }
      } catch (error) {
        console.log(error);
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: this.$t(message),
        });
      }
    },

    async saveChange() {
      try {
        const contractResponse = await ContractApi.confirmSent(
          this.selectedContract.id
        );
        this.$q.notify({
          type: "positive",
          message: this.$t(contractResponse.data.message),
        });
        this.refresh();
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async onRequest(props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;
      this.loading = true;
      try {
        const startRow = (page - 1) * rowsPerPage;
        const pageReponse = await ContractApi.paginate({
          startRow,
          fetchCount: rowsPerPage,
          filter,
          sortBy,
          descending,
        });

        this.rows = pageReponse.data.items;
        this.pagination.rowsNumber = pageReponse.data.count;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async refresh() {
      this.confirmContractSent = false;
      this.onRequest({
        pagination: this.pagination,
        filter: this.filter,
      });
    },

    statusColor(status) {
      return ContractStatus[status].color;
    },
    productImage(product) {
      if (product && product.image)
        return (
          process.env.VUE_APP_BASE_URL + "/product/images/" + product.image
        );
    },
    async copyContact() {
      const contact = `${this.selectedContract.contact.address.firstname} ${this.selectedContract.contact.address.lastname}\n
${this.selectedContract.contact.address.address}\n
${this.selectedContract.contact.address.zip} ${this.selectedContract.contact.address.city}`;
      try {
        await copyToClipboard(contact);
        this.$q.notify({
          type: "positive",
          message: this.$t("message.copied"),
        });
      } catch (error) {
        this.$q.notify({
          type: "negative",
          message: error.message,
        });
      }
    },
  },

  async mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: this.filter,
    });
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "created_at",
          label: this.$t("label.created"),
          field: "created_at",
          align: "center",
          sortable: true,
        },
        {
          name: "contact",
          label: this.$t("label.contact"),
          field: "contact",
          align: "center",
          sortable: false,
        },
        {
          name: "product",
          label: this.$t("label.product"),
          field: "product",
          align: "center",
          sortable: false,
        },
        {
          name: "order",
          label: this.$t("label.order"),
          field: "order",
          align: "center",
          sortable: false,
        },
        {
          name: "status",
          align: "center",
          label: this.$t("label.status"),
          field: "status",
          sortable: true,
        },
        {
          name: "dateSent",
          label: this.$t("label.dateSent"),
          field: "dateSent",
          align: "center",
          sortable: true,
        },
        { name: "actions", align: "right", label: this.$t("label.actions") },
      ];
      return columns;
    },
  },
};
</script>
